import { useState } from "react";
import { IChallenge } from "../../screens/AdminUploadCampaignScreen";
import ChallengeForm from "./ChallengeForm";
import { useForm } from "../../hooks/useForm";
import Button from "../../base/Button.base";

const ChallengesList = ({
  challenges = [],
  setChallenges,
}: {
  challenges: IChallenge[];
  setChallenges: React.Dispatch<React.SetStateAction<IChallenge[]>>;
}) => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const { form, onChange, setFormValue } = useForm<IChallenge>({
    challenge_reward_amount: "",
    challenge_data_url: "",
    challenge_data_platform: "instagram",
    challenge_data_title: "",
    challenge_data_instructions: "",
  });

  const uploadChallengeHandler = () => {
    setChallenges([...challenges, form]);
    setIsCreating(false);
    setFormValue({
      challenge_reward_amount: "",
      challenge_data_url: "",
      challenge_data_platform: "instagram",
      challenge_data_title: "",
      challenge_data_instructions: "",
    });
  };

  const handleDeleteChallenge = (challenge: IChallenge) => {
    setChallenges(
      challenges.filter(
        (c) => c.challenge_data_title !== challenge.challenge_data_title
      )
    );
  };

  return (
    <>
      <div className="AdminUploadCampaignScreen__Content-challenge-titles">
        {challenges.length !== 0 && <div style={{ width: "80px" }}></div>}
        <h1 className="AdminUploadCampaignScreen__Content-title">Challenges</h1>
        {challenges.length !== 0 && !isCreating && (
          <Button
            isPrincipal
            onClick={() => setIsCreating(true)}
            style={{
              marginRight: "10px",
              width: "65px",
            }}
            value="New"
          />
        )}
        {isCreating && (
          <Button
            isPrincipal
            onClick={() => setIsCreating(false)}
            style={{
              marginRight: "10px",
              width: "65px",
            }}
            value="Cancel"
          />
        )}
      </div>
      <div className="AdminUploadCampaignScreen__Content-form">
        {!isCreating && challenges.length === 0 ? (
          <Button
            isPrincipal
            onClick={() => setIsCreating(true)}
            className="AdminUploadCampaignScreen__Content-form-button"
            value="Create a new challenge"
          />
        ) : (
          !isCreating && (
            <div className="AdminUploadCampaignScreen__Content-challenge-list">
              {challenges.map((challenge) => (
                <div
                  key={challenge.challenge_data_title}
                  className="AdminUploadCampaignScreen__Content-challenge-container"
                >
                  <h2 style={{ margin: 0 }}>
                    {challenge.challenge_data_title}
                  </h2>
                  <div className="AdminUploadCampaignScreen__Content-challenge-actions">
                    <h3 style={{ margin: 0 }}>
                      {challenge.challenge_reward_amount}
                    </h3>
                    <Button
                      onClick={() => handleDeleteChallenge(challenge)}
                      style={{ color: "red" }}
                      value="Delete"
                    />
                  </div>
                </div>
              ))}
            </div>
          )
        )}
        {isCreating && (
          <ChallengeForm
            form={form}
            onChange={onChange}
            uploadChallengeHandler={uploadChallengeHandler}
          />
        )}
      </div>
    </>
  );
};

export default ChallengesList;
