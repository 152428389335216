import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";

interface ToastProps {
  children?: string;
  autoHideDuration?: number;
  onClose?: (event?: React.SyntheticEvent | Event, reason?: string) => void;
  severity?: AlertColor;
}

const useToast = (options: ToastProps = {}) => {
  const { autoHideDuration, severity } = options;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const Toast: React.FC<ToastProps> = ({ severity, children }) => {

   return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      style={{width:"20%"}}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }} style={{ justifyContent:"center", alignItems:"center", fontSize:"1.4rem" }}>
        {children}
      </Alert>
    </Snackbar>
  )};

  useEffect(() => {
    if (autoHideDuration) {
      const timer = setTimeout(() => {
        handleClose();
      }, autoHideDuration);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [open, autoHideDuration]);

  return {
    Toast,
    handleClick,
    handleClose,
  };
};

export default useToast;
