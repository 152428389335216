import { useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "../../../contexts/LocalizeContext";

interface countryCount {
  _id: string;
  count: number;
}

export interface IOverviewUsers {
  usersCountryStatistics: countryCount[];
  usersOsStatistics: {
    androidUsersCount: number;
    iosUsersCount: number;
  };
}

const generateOptionsChart = (serie: number[], labels: string[]) => {
  return {
    options: {
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: false,
          customScale: 0.8,
          dataLabels: {
            offset: 44,
            minAngleToShowLabel: 10,
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: number, opt: any) {
          return opt.w.globals.labels[opt.seriesIndex] + "\n" + val + "%";
        },
        style: {
          fontWeight: "600",
          colors: ["#000"],
        },
        dropShadow: {
          enabled: false,
        },
      },
      legend: {
        show: false,
      },
      series: serie,
      chart: {
        width: 200,
        type: "pie",
      },
      labels: labels,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 150,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
};

const Overview = ({
  usersCountryStatistics,
  usersOsStatistics,
}: IOverviewUsers) => {
  const {
    APP: { USER_STATISTICS },
  } = useTranslation();

  const [userGenereState, setUserGenereState] = useState<{
    // emptyChart: any;
    locationChart: any;
    platformChart: any;
  }>({
    // emptyChart: generateOptionsChart([40, 60], ["Legend1", "Legend2"]),
    locationChart: generateOptionsChart(
      usersCountryStatistics.map((countryCount) => countryCount.count),
      usersCountryStatistics.map((countryCount) => countryCount._id)
    ),
    platformChart: generateOptionsChart(
      [usersOsStatistics.androidUsersCount, usersOsStatistics.iosUsersCount],
      [
        USER_STATISTICS.GRAPHICS.USERS_PLATFORM.ANDROID,
        USER_STATISTICS.GRAPHICS.USERS_PLATFORM.IOS,
      ]
    ),
  });

  return (
    <div className="overview">
      {/* <div className="overview-item">
        <h3 className="overview-side__card-title">Coming soon</h3>
        <div className="overview-side__card-body">
          <Chart
            options={userGenereState.emptyChart.options}
            type={"pie"}
            series={userGenereState.emptyChart.options.series}
            chart={userGenereState.emptyChart.options.chart}
            labels={userGenereState.emptyChart.options.labels}
            responsive={userGenereState.emptyChart.options.responsive}
          />
        </div>
      </div> */}
      <div className="overview-item">
        <h3 className="overview-side__card-title">
          {USER_STATISTICS.GRAPHICS.USERS_LOCATION.TITLE}
        </h3>
        <div className="overview-side__card-body">
          <Chart
            options={userGenereState.locationChart.options}
            type={"pie"}
            series={userGenereState.locationChart.options.series}
            chart={userGenereState.locationChart.options.chart}
            labels={userGenereState.locationChart.options.labels}
            responsive={userGenereState.locationChart.options.responsive}
          />
        </div>
      </div>
      <div className="overview-item">
        <h3 className="overview-side__card-title">
          {USER_STATISTICS.GRAPHICS.USERS_PLATFORM.TITLE}
        </h3>
        <div className="overview-side__card-body">
          <Chart
            options={userGenereState.platformChart.options}
            type={"pie"}
            series={userGenereState.platformChart.options.series}
            chart={userGenereState.platformChart.options.chart}
            labels={userGenereState.platformChart.options.labels}
            responsive={userGenereState.platformChart.options.responsive}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
