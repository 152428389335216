import { FC, useEffect } from "react";
import { Styles } from "./PointsScreenView.styles";
// import { DashboardHeader } from "../../../components/Dashboard/Header/DashboardHeader";
import { ButtonPresets, InputPresets, TextPresets } from "../../../components/UI";
import { Card } from "../../../components/UI/Card"
import useToast from "../../../hooks/useToast";
import { AlertColor } from "@mui/material/Alert";
import { SelectPresets } from "../../../components/UI/Select";

interface PointsScreenProps {
  generatedCode: string;
  generateCode: () => void;
  saveCode: () => void;
  showAlert: boolean;
  statusAlert: string;
  msg: string;
  setShowAlert: any;
  setStatusAlert: any;
  pointsAmount: number;
  codeType: "unique" | "multi"
  setPointsAmount: (points:number) => void;
  setCodeType: (type: "unique"|"multi") => void;
  Toast: any
  handleClick: () => void;
  handleClose: () => void;
}

const PointsScreenView: FC<PointsScreenProps> = ({
  generateCode,
  generatedCode,
  saveCode,
  showAlert,
  statusAlert,
  msg,
  setShowAlert,
  setStatusAlert,
  pointsAmount,
  setCodeType,
  codeType,
  setPointsAmount,
  Toast,
  handleClick,
  handleClose,
}) => {

  return (
    <Styles>
      {/* <DashboardHeader sectionName="Points" /> */}
        <Card className="DB_Charts-Container">
          <div className="DB_Charts-GenerateComponent">
            <ButtonPresets.ButtonRoundedFilledPrimary
              style={{maxWidth:"15rem", minWidth:"15rem"}}
              label="GENERATE"
              onClick={generateCode}
            />
            <InputPresets.TextInput
              className="DB_Charts-Search-Input"
              placeholder="code"
              disabled
              value={generatedCode}
            />
          </div>
          <div className="DB_Charts-PointsComponent">
            <TextPresets.BodyBold style={{maxWidth:"15rem", minWidth:"15rem", textAlign:"center"}}>
              Points Amount
            </TextPresets.BodyBold>
            <InputPresets.TextInput
              className="DB_Charts-Search-Input"
              placeholder="Points"
              type="number"
              value={pointsAmount}
              onChange={(e) => setPointsAmount(+e.target.value)}
            />
          </div>

          <div className="DB_Charts-TypeComponent">
            <TextPresets.BodyBold style={{maxWidth:"15rem", minWidth:"15rem", textAlign:"center"}}>
              Code Type
            </TextPresets.BodyBold>
            <SelectPresets.SelectInput
              placeholder="Select type"
              options={[{value:"unique", label:"Single Use" }, {value:"multi", label:"Multiple Use"}]}
              onChangeValue={(value) =>{
                if(value === ""){
                  setCodeType("unique")
                }else{
                  setCodeType(value as "multi"|"unique")}}
                }
              value={codeType}
              style={{ padding: "0.5rem 0", maxWidth:"12rem" }}
              
            />
          </div>
        </Card>

      <Card className="DB_Charts-SecondContainer">
        <ButtonPresets.ButtonOutlinedPrimary
          label="CREATE CODE"
          onClick={saveCode}
          disabled={generatedCode === ""}
        />
      </Card>
      {showAlert && (
        <Toast onClose={handleClose} severity={statusAlert}>
          {msg}
        </Toast>
      )}
    </Styles>
  );
};

export default PointsScreenView;
