import { useEffect, useState } from "react";
import Input, { IOptions } from "../base/Input.base";
import "../styles/pages/AdminUploadFilterScreen.scss";
import Button from "../base/Button.base";
import { getAllFilterCategories, uploadFilter } from "../api/filter.api";

export default function AdminUploadFilterScreen() {
  const [sendingForm, setSendingForm] = useState(false);

  const [categoryOptions, setCategoryOptions] = useState<IOptions[]>([]);

  const [filterName, setFilterName] = useState("");
  const [filterLocation, setFilterLocation] = useState("")
  const [category, setCategory] = useState("");
  const [filterUrl, setFilterUrl] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showInfo, setShowInfo] = useState(false)

  useEffect(() => {
    
    (async () => {
      const categories = await getAllFilterCategories();

      console.log(categories);

      const formattedCategories = [{
        _id: "",
        name: "Select a category *",
        image: "",
      }].concat(categories.map((item: any) => ({
        _id: item.name,
        name: item.name,
        image: "",
      })));

      setCategoryOptions(formattedCategories);
    })()
  }, []);


  const uploadFilterHandler = async () => {
    if(sendingForm){
      return;
    }

    setSendingForm(true);
    setSuccessMessage("")
    setErrorMessage("")

    const response = await uploadFilter(filterName, filterLocation, category, filterUrl, previewUrl, videoUrl);

    switch(response){
      case "SUCCESS": {
        setSuccessMessage("The filter has been uploaded successfully!")
        setErrorMessage("");
        setFilterName("");
        setCategory("")
        setFilterUrl("")
        setPreviewUrl("")
        setVideoUrl("")
        break;
      }
      case "INVALID_NAME": {
        setSuccessMessage("")
        setErrorMessage("The provided filter name is invalid!");
        break;
      }

      case "INVALID_FILTER_URL": {
        setSuccessMessage("")
        setErrorMessage("The provided filter url is invalid!");
        break;
      }
      case "INVALID_PREVIEW_URL": {
        setSuccessMessage("")
        setErrorMessage("The provided preview url is invalid!");
        break;
      }
      case "INVALID_VIDEO_URL": {
        setSuccessMessage("")
        setErrorMessage("The provided video url is invalid!");
        break;
      }
      case "INVALID_CATEGORY": {
        setSuccessMessage("")
        setErrorMessage("The provided category!");
        break;
      }
      case "CATEGORY_NOT_FOUND": {
        setSuccessMessage("")
        setErrorMessage("The provided category has not been found!");
        break;
      }
      case "FILTER_ALREADY_EXISTS": {
        setSuccessMessage("")
        setErrorMessage("The provided filter already exists!");
        break;
      }
      case "CREATOR_NOT_FOUND": {
        setSuccessMessage("")
        setErrorMessage("The user filtroo.co does not exist!");
        break;
      }
      case "UNKNOWN": {
        setSuccessMessage("")
        setErrorMessage("An unknown error has happened!");
        break;
      }
    }
    setSendingForm(false);
  }

  const handleShowInfo = () => {
    setShowInfo(!showInfo)
  }


  return (
    <div
      className="AdminUploadFilterScreen"
    >
      <div className="AdminUploadFilterScreen__Content">
        <h1 className="AdminUploadFilterScreen__Content-title">Upload a new filter</h1>
        <div className="AdminUploadFilterScreen__Content-form">
          <Input
              isFull
              className="AdminUploadFilterScreen__Content-form-input"
              name="filterName"
              type="text"
              value={filterName}
              placeholder="Filter name *"
              onChange={setFilterName}
            />
          <Input
            isSelector
            options={categoryOptions}
            className="AdminUploadFilterScreen__Content-form-input"
            name="category"
            type="text"
            value={category}
            placeholder="Categoría"
            onChange={setCategory}
          />
          <Input
              isFull
              className="AdminUploadFilterScreen__Content-form-input"
              name="filterUrl"
              type="text"
              value={filterUrl}
              placeholder="Filter url from Tiktok *"
              onChange={setFilterUrl}
            />
          <Input
              isFull
              className="AdminUploadFilterScreen__Content-form-input"
              name="previewUrl"
              type="text"
              value={previewUrl}
              placeholder="Image preview url *"
              onChange={setPreviewUrl}
            />
          <Input
              isFull
              className="AdminUploadFilterScreen__Content-form-input"
              name="videoUrl"
              type="text"
              value={videoUrl}
              placeholder="Video url *"
              onChange={setVideoUrl}
            />
          <Input
              isFull
              className="AdminUploadFilterScreen__Content-form-input"
              name="filterLocation"
              type="text"
              value={filterLocation}
              placeholder="TikTok account to be mentioned"
              onChange={setFilterLocation}
              infoText="In case this field is left empty, 'filtroo.co' will be the default value"
              handleShowInfo={handleShowInfo}
              showInfo={showInfo}
            />
            <Button
            isPrincipal
            className="AdminUploadFilterScreen__Content-form-button"
            loading={sendingForm}
            onClick={uploadFilterHandler}
            value="Subir filtro"
          />
          <div className="AdminUploadFilterScreen__Content-result">
            {successMessage ? <span className="AdminUploadFilterScreen__Content-result--success">{successMessage}</span> : null}
            {errorMessage ? <span className="AdminUploadFilterScreen__Content-result--error">{errorMessage}</span> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
