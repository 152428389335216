import { FC, useEffect, useState } from "react";
import PointsScreenView from "./Views/PointsScreenView";
import { AlertColor } from "@mui/material/Alert";
import { usePointsGenerator } from "../../hooks/usePointsGenerator";
import { Container } from "@mui/material";
import useToast from "../../hooks/useToast";

const PointsScreen: FC = () => {
  const pointsGenerator = usePointsGenerator({});
  const [generatedCode, setGeneratedCode] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [statusAlert, setStatusAlert] = useState("success");
  const [msg, setMsg] = useState<string>("");
  const [pointsAmount, setPointsAmount ] = useState(100)
  const { Toast, handleClick, handleClose } = useToast()
  const [codeType, setCodeType] = useState<"unique"|"multi">("unique")

  const generateCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let code = "";
    for (let i = 0; i < 9; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters[randomIndex];
    }
    setGeneratedCode(code);
  };

  const saveCode = async () => {
    try {
      console.log("al",statusAlert)
      const response = await pointsGenerator.generateCode(generatedCode, codeType, pointsAmount);
      if (response ) {
        setShowAlert(true);
        setStatusAlert("success");
        setMsg("SUCCESSFULLY GENERATED CODE");
      } else {
        setStatusAlert("error")
        setShowAlert(true);
        setMsg("CODE ALREADY EXISTS");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (showAlert) {
      handleClick();
    }
  }, [showAlert]);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        handleClose();
        setShowAlert(false);
        setStatusAlert("");
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showAlert]);

  return (
    <Container>
      <PointsScreenView
        pointsAmount={pointsAmount}
        codeType={codeType}
        setPointsAmount={setPointsAmount}
        setCodeType={setCodeType}
        generateCode={generateCode}
        generatedCode={generatedCode}
        saveCode={saveCode}
        showAlert={showAlert}
        statusAlert={statusAlert}
        msg={msg}
        setShowAlert={setShowAlert}
        setStatusAlert={setStatusAlert}
        Toast={Toast}
        handleClick={handleClick}
        handleClose={handleClose}
/>
    </Container>
  );
};

export default PointsScreen;
