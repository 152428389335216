import { IChallenge } from "../screens/AdminUploadCampaignScreen";
import { ApiService } from "../services/ApiService";

export const uploadCampaign = async ({
  campaignName,
  campaignDescription,
  campaignBudget,
  startDate,
  endDate,
  challenges,
  imageCampaignFile,
  imageBannerFile,
}: {
  campaignName: string;
  campaignDescription: string;
  campaignBudget: string;
  startDate: string;
  endDate: string;
  challenges: IChallenge[];
  imageCampaignFile: File[];
  imageBannerFile: File[];
}) => {
  try {
    const { client: api } = new ApiService();
    const formData = new FormData();
    formData.append("campaignImage", imageCampaignFile[0]);
    formData.append("bannerImage", imageBannerFile[0]);
    formData.append("campaign_name", campaignName);
    formData.append("campaign_description", campaignDescription);
    formData.append("campaign_budget_amount", campaignBudget);
    formData.append("campaign_startDate", startDate);
    formData.append("campaign_endDate", endDate);
    formData.append("challenges", JSON.stringify(challenges));

    const { data } = await api.post("/stores/campaigns/admin", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};
