import styled from 'styled-components';
import {GrayScale, MainColors, Status, Background} from '../../../../assets/theme/Colors';
import {SpinnerPresets} from '../../Spinners';
import {Button, ButtonProps} from '../Button';
import { transparentize } from 'polished';

const Styles = styled(Button)`
  background-color: ${Background.bgLight};
  border: ${({disabled, loading}) => (disabled && !loading ? `1px solid ${Status.disabled}` : `1px solid ${MainColors.primary}`)};
  color: ${({disabled, loading}) => (disabled && !loading ? Status.disabled : GrayScale.darkOverLight)};
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  transition: all 400ms ease-in-out;

  &:hover {
    background-color: #4592d3;
    color: ${GrayScale.lightOverDark};
    border: 1px solid #4592d3 };

    & .Button__loader > div {
      border-color: #4592d3 transparent transparent transparent;
    }
  }
`;

export function ButtonOutlinedPrimary({...props}: ButtonProps) {
  return <Styles {...props} loader={<SpinnerPresets.Circular className='Button__loader' color={MainColors.primary} />} />;
}
