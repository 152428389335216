import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { createContext, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import filtrooApi from "../../api/filtrooApi";
import { STORE } from "../../constants/store.constants";
import { User, LoginData, LoginResponse } from "../../interfaces/appInterfaces";
import { useStore } from "../StoreContext";
import { AuthReducer, AuthState } from "./AuthReducer";

type AuthContextProps = {
  errorMessage: string;
  token: string | null;
  user: User | null;
  status: "authenticated" | "unauthorized";
  // signUp: (signupData: RegisterData) => void;
  signIn: (loginData: LoginData) => Promise<any>;
  // logOut: () => void;
  signOut: () => void;
  removeError: () => void;
  signinLoading: boolean;
  addError: (error: string) => void;
};

const authInitialState: AuthState = {
  status: "unauthorized",
  token: null,
  user: null,
  errorMessage: "",
};

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({ children }: any) => {
  const { set, get, clear } = useStore();
  const [status, setStatus] = useState<"unauthorized" | "authenticated">(
    "unauthorized"
  );
  const [state, dispatch] = useReducer(AuthReducer, authInitialState);
  const [signinLoading, setSigninLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = get(STORE.TOKEN);
    console.log(token);
    if (token) {
      filtrooApi.interceptors.request.use(async (config:any) => {
        config.headers = {
          Authorization: token,
        };
        return config;
      });
      setStatus("authenticated");
    }
  }, []);

  const signIn = async ({ email, password }: LoginData) => {
    return new Promise(async (resolve, reject) => {
      console.log("sigin se ejecuta");
      setSigninLoading(true);
      try {
        const res = await filtrooApi.post<LoginResponse>(`/login`, {
          email,
          password,
        });
        // const res = await axios.post<LoginResponse>('http://192.168.1.137:8000/backoffice/login', {email, password});
        setSigninLoading(false);
        console.log(res);
        const { backofficeUser, token } = res.data;
        set(STORE.TOKEN, token);
        set(STORE.USER, backofficeUser);
        filtrooApi.interceptors.request.use(async (config:any) => {
          config.headers = {
            Authorization: get("TOKEN"),
          };
          return config;
        });
        dispatch({
          type: "signIn",
          payload: {
            token,
            user: backofficeUser,
          },
        });
        if (backofficeUser?.rol === "company") {
          if (backofficeUser?.companyId) {
            resolve(window.location.replace("/home"));
          } else {
            resolve(window.location.replace("/on-boarding"));
          }
        } else {
          resolve(window.location.replace("/admin/users"));
        }

        // await setAsyncStorage('token', res.data.token);
      } catch (error: any) {
        setSigninLoading(false);
        console.log(error);
        reject(
          dispatch({
            type: "addError",
            payload: error.response.data.msg || "Unable to login",
          })
        );
      }
    });
  };

  // const logOut = async () => {
  //     await AsyncStorage.removeItem('token');
  //     //eliminar reward activo de local storage
  //     await AsyncStorage.removeItem('activeRewards');
  //     dispatch({type: 'logout'});
  //     // dispatch exchangeRewardEnd
  //     // exchangeRewardEnd();
  //     deleteActiveRewards();
  // };

  const removeError = () => {
    dispatch({
      type: "removeError",
    });
  };

  const addError = (error: string) => {
    dispatch({
      type: "addError",
      payload: error,
    });
  };

  const signOut = () => {
    set(STORE.TOKEN, undefined);
    set(STORE.USER, undefined);
    setStatus("unauthorized");
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        // signUp,
        signIn,
        signOut,
        // logOut,
        removeError,
        signinLoading,
        addError,
        status,
        // token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
