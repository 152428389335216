import styled from 'styled-components';
import {ButtonProps} from './Button';

export const Styles = styled.div<ButtonProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
  user-select: none;
  font-size: 1.4rem;
`;
