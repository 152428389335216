import { IChallenge } from "../../screens/AdminUploadCampaignScreen";
import Input from "../../base/Input.base";
import Button from "../../base/Button.base";

const ChallengeForm = ({
  form,
  onChange,
  uploadChallengeHandler,
}: {
  form: IChallenge;
  onChange: (value: string, field: keyof IChallenge) => void;
  uploadChallengeHandler: () => void;
}) => {
  return (
    <>
      <h1 className="AdminUploadCampaignScreen__Content-title">
        Add a new challenge
      </h1>
      <div className="AdminUploadCampaignScreen__Content-form">
        <Input
          isFull
          className="AdminUploadCampaignScreen__Content-form-input"
          name="challenge_data_title"
          type="text"
          value={form.challenge_data_title}
          placeholder="Challenge title *"
          onChange={(value: any) => onChange(value, "challenge_data_title")}
        />
        <Input
          isFull
          className="AdminUploadCampaignScreen__Content-form-input"
          name="challenge_data_instructions"
          type="text"
          isArea
          value={form.challenge_data_instructions}
          placeholder="Instructions"
          onChange={(value: any) =>
            onChange(value, "challenge_data_instructions")
          }
        />
        <Input
          isFull
          className="AdminUploadCampaignScreen__Content-form-input"
          name="challenge_reward_amount"
          type="number"
          value={form.challenge_reward_amount}
          placeholder="Challenge reward amount *"
          onChange={(value: any) => onChange(value, "challenge_reward_amount")}
        />
        <Input
          isSelector
          isFull
          className="AdminUploadCampaignScreen__Content-form-input"
          name="challenge_data_platform"
          type="text"
          value={form.challenge_data_platform}
          placeholder="Platform *"
          onChange={(value: any) => onChange(value, "challenge_data_platform")}
          options={[
            { _id: "instagram", name: "Instagram", image: "" },
            { _id: "twitter", name: "Twitter", image: "" },
            { _id: "tiktok", name: "TikTok", image: "" },
            { _id: "website", name: "Website", image: "" },
          ]}
        />
        <Input
          isFull
          className="AdminUploadCampaignScreen__Content-form-input"
          name="challenge_data_url"
          type="text"
          value={form.challenge_data_url}
          placeholder="Redirect URL *"
          onChange={(value: any) => onChange(value, "challenge_data_url")}
        />

        <Button
          isPrincipal
          className="AdminUploadCampaignScreen__Content-form-button"
          onClick={uploadChallengeHandler}
          value="Add challenge"
        />
      </div>
    </>
  );
};

export default ChallengeForm;
