import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { LoginScreen } from "../screens/LoginScreen";
import { AuthContext } from "../contexts/authContext/AuthContext";
import Layout from "../components/Layout";
import AdminLayout from "../components/Admin/AdminLayout";
import HomeScreen from "../screens/HomeScreen";
import AdminHomeScreen from "../screens/AdminHomeScreen";
import StatisticsScreen from "../screens/StatisticsScreen";
import OnBoarding from "../screens/OnBoarding";
import ProfileScreen from "../screens/ProfileScreen";
import { useStore } from "../contexts/StoreContext";
import LinkScreen from "../screens/LinkScreen";
import UserStatisticsScreen from "../screens/UserStatisticsScreen";
import AdminCategoriesScreen from "../screens/AdminCategoriesScreen";
import AdminProfileScreen from "../screens/AdminProfileScreen";
import AdminUploadFilterScreen from "../screens/AdminUploadFilterScreen";
import ApprovedFilterScreen from "../screens/ApprovedFilterScreen";
import AdminFilterDetailScreen from "../screens/AdminFilterDetailScreen";
import PointsScreen from "../screens/PointsScreen/PointsScreen";
import AdminPointsRewardsScreen from "../screens/AdminPointsRewardsScreen";
import { PointsGenerator } from "../screens/PointsGeneratorScreen";
import AdminUploadCampaignScreen from "../screens/AdminUploadCampaignScreen";
export const AppRouter = () => {
  const { get } = useStore();
  const { status, token } = useContext(AuthContext);
  // const auth = true;

  const navigate = useNavigate();

  useEffect(() => {
    console.log(status, token, get("USER"));
    if (status === "unauthorized") {
      return navigate("/", { replace: true });
    } else {
      const user = get("USER");
      if (user?.rol === "company") {
        if (user?.companyId) {
          navigate("/home", { replace: true });
        } else {
          navigate("/on-boarding", { replace: true });
        }
      } else {
        navigate("/admin/users", { replace: true });
      }
    }
  }, [token, status]);

  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path="on-boarding" element={<OnBoarding />} />
      <Route path="/*" element={<Layout />}>
        <Route path="home" element={<HomeScreen />} />
        <Route path="statistics" element={<StatisticsScreen />} />
        <Route path="profile" element={<ProfileScreen />} />
        <Route path="links" element={<LinkScreen />} />
        <Route path="userstatistics" element={<UserStatisticsScreen />} />
      </Route>
      <Route path="/admin/*" element={<AdminLayout />}>
        <Route path="users" element={<AdminHomeScreen />} />
        <Route path="categories" element={<AdminCategoriesScreen />} />
        <Route path="profile" element={<AdminProfileScreen />} />
        <Route path="upload-filter" element={<AdminUploadFilterScreen />} />
        <Route path="upload-campaign" element={<AdminUploadCampaignScreen />} />
        <Route path="approved-filter" element={<ApprovedFilterScreen />} />
        <Route path="filters/:id" element={<AdminFilterDetailScreen />} />
        <Route path="points-rewards" element={<AdminPointsRewardsScreen />} />
        <Route path="generate-points-code" element={<PointsScreen/>} ></Route>
        <Route path="points-generator" element={<PointsGenerator></PointsGenerator>} />
      </Route>
    </Routes>
  );
};
