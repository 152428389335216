import {MainColors} from '../../../../assets/theme/Colors';
import {Circular} from './Circular';

export const ScreenLoader = ({...props}) => {
    return <div style={{
      display:"flex",
      height:"100vh",
      width:"100%",
      justifyContent:"center",
      alignItems:"center",
  }}>
      <Circular color={MainColors.primary} size={64} thickness={5} {...props} />
  </div>
  // return <Circular color={MainColors.primary} size={128} thickness={5} {...props} />;
}
