import styled from 'styled-components';
import {GrayScale, MainColors, Status, Background} from '../../../../assets/theme/Colors';
import {SpinnerPresets} from '../../Spinners';
import {Button, ButtonProps} from '../Button';

const Styles = styled(Button)`
  background-color: ${Background.bgLight};
  border: ${({disabled, loading}) => (disabled && !loading ? `2px solid ${Status.disabled}` : `2px solid ${MainColors.primary}`)};
  color: ${({disabled, loading}) => (disabled && !loading ? Status.disabled : GrayScale.darkOverLight)};
  padding: 0.5rem 1rem;
  border-radius: 3rem;
  transition: all 400ms ease-in-out;

  &:hover {
    border: 2px solid ${MainColors.primary};
  }
`;

export function ButtonRounded({...props}: ButtonProps) {
  return <Styles {...props} loader={<SpinnerPresets.Circular className='Button__loader' color={MainColors.primary} />} />;
}
