import React, { useEffect, useState } from "react";
import Button from "../base/Button.base";
import Dragger from "../base/Dragger.base";
import Input from "../base/Input.base";
import { useTranslation } from "../contexts/LocalizeContext";
import { useForm } from "../hooks/useForm";
import "../styles/pages/adminCategories.scss";
import { X, Gift } from "react-feather";
import Categories from "../components/Admin/Categories";
import { getCategories } from "../api/onBoarding.api";
import { newCategory } from "../api/admin.api";

// mocks
// const categories = [
//   {"_id":"62470ff2900265f111772e8b","name":"restaurant","active":true,"image":"https://res.cloudinary.com/netspot/image/upload/v1648824306/filtroo/categories/cyaea3urcuxxdma5wspp.png","__v":0},
//   {"_id":"62470ff2900265f111772e8b","name":"games","active":false,"image":"https://res.cloudinary.com/netspot/image/upload/v1648824306/filtroo/categories/cyaea3urcuxxdma5wspp.png","__v":0},
//   {"_id":"62470ff2900265f111772e8b","name":"challenges","active":false,"image":"https://res.cloudinary.com/netspot/image/upload/v1648824306/filtroo/categories/cyaea3urcuxxdma5wspp.png","__v":0},
//   {"_id":"62470ff2900265f111772e8b","name":"Celebrities","active":true,"image":"https://res.cloudinary.com/netspot/image/upload/v1648824306/filtroo/categories/cyaea3urcuxxdma5wspp.png","__v":0},
// ]

export default function AdminCategoriesScreen() {
  const [loading, setLoading] = useState(true);
  const [isUploadingCategory, setIsUploadingCategory] = useState(false);
  const [categories, setCategories] = useState<any>([]);
  const {
    APP: { ADMIN },
  } = useTranslation();

  useEffect(() => {
    getCategories()
      .then(({data:categoriesResponse}) => {
        setCategories(categoriesResponse);
        setLoading(false);
        setIsUploadingCategory(false);
      })
      .catch((err) => console.error(err));
  }, []);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { name, onChange, form } = useForm({
    name: "",
  });

  const [imageFile, setImageFile] = useState<File[] | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");

  const submitNewCategory = async () => {
    try {
      setIsUploadingCategory(true);
      if (!name) {
        setIsUploadingCategory(false);
        return setErrorMessage("Empty field.");
      }
      if (!imageFile) {
        setIsUploadingCategory(false);
        return setErrorMessage("Image file empty.");
      }
      if (imageFile![0].size > 1500000) {
        setIsUploadingCategory(false);
        return setErrorMessage("Max image size 1.5MB");
      }
      if (imageFile![0].type === "image/jpeg" || "image/png") {
        console.log("success");
      }
      // submit new Category
      // const companyId = get(STORE.USER).companyId;
      const category = await newCategory(name, imageFile);
      onChange("", "name");
      setImageFile(null);
      setImageUrl("");
      setIsUploadingCategory(false);
      setCategories((prev: any) => ([
        ...prev,
        category
      ]));
    } catch {
      setIsUploadingCategory(false);
    }
  };

  return (
    <div className="admin-categories-container">
      <Categories
        onEdit={(id: string, category: any) => {
          const arr = [...categories];
          const categoriesArr = arr.map((i) => {
            if (i._id === id) {
              return category;
            }
            return i;
          });
          setCategories(categoriesArr);
        }}
        onDelete={(id: string) => {
          const arr = [...categories];
          const categoriesArr = arr.filter((i) => i._id !== id);
          setCategories(categoriesArr);
        }}
        cards={categories}
      />
      <form>
        <Gift
          className="admin-categories-form-icon"
          size={110}
          onClick={() => setErrorMessage(null)}
        />
        <h1>{ADMIN.CATEGORIES.TITLE}</h1>
        <p>{ADMIN.CATEGORIES.PARAGRAPH}</p>
        <Input
          name="name"
          value={name}
          maxLength={50}
          type="text"
          isFull
          placeholder={ADMIN.CATEGORIES.FORM.NAME}
          onChange={(value: any, name: any) => {
            onChange(value, name);
          }}
          style={{ marginTop: 15 }}
        />

        <Dragger
          defaultImage={imageUrl}
          style={{ marginTop: 15, marginBottom: 20 }}
          onChange={(file: any, url: string) => {
            setImageFile(file);
            setImageUrl(url);
          }}
        />

        {errorMessage && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
              color: "red",
              fontWeight: "bold",
            }}
          >
            <span>{errorMessage}</span>
            <X
              style={{ color: "red", marginLeft: 5 }}
              size={18}
              onClick={() => setErrorMessage(null)}
            />
          </div>
        )}

        <Button
          onClick={submitNewCategory}
          isPrincipal
          style={{ marginBottom: 30 }}
          value={ADMIN.CATEGORIES.FORM.CREATE}
          loading={isUploadingCategory ? true : false}
        />
      </form>
    </div>
  );
}
