import { stat } from "fs";
import React, { useState } from "react";
import Button from "../../base/Button.base";
import Dragger from "../../base/Dragger.base";
import GoBack from "../../base/GoBack.base";
import Input from "../../base/Input.base";
import { useTranslation } from "../../contexts/LocalizeContext";
import { RewardsResponse } from "../../interfaces/appInterfaces";
import "../../styles/pages/onBoarding.scss";

export default function Rewards(props: {
  onSubmit: any;
  onAdd: any;
  onBack: any;
  rewards: Array<Partial<RewardsResponse>>;
}) {
  const {
    APP: { ON_BOARDING, HOME },
  } = useTranslation();

  const [added, setAdded] = useState(false);

  const [state, setState] = useState({
    image: "",
    file: "",
    name: "",
    coinsPrice: "",
  });

  console.log(props.rewards);

  const updateState = (value: string, name: string) =>
    setState((prevState) => ({ ...prevState, [name]: value }));

  return (
    <form className="on-boarding-children">
      <Dragger
        defaultImage={state?.image}
        style={{ marginTop: 30 }}
        onChange={(file: any, url: string) => {
          setState((prevState) => ({
            ...prevState,
            image: url,
            file,
          }));
        }}
      />
      <Input
        name="name"
        maxLength={50}
        type="text"
        isFull
        value={state.name}
        placeholder={HOME.REWARDS.FORM.NAME}
        onChange={updateState}
        style={{ marginTop: 15 }}
      />
      <div style={{ width: 200 }}>
        <Input
          name="coinsPrice"
          type="number"
          isFull
          value={state.coinsPrice}
          placeholder={HOME.REWARDS.FORM.COINS}
          onChange={updateState}
          style={{ marginTop: 15 }}
        />
      </div>
      <div
        className="on-boarding__buttons"
        style={{ justifyContent: "flex-start", marginLeft: 0 }}
      >
        <Button
          disabled={!state.image || !state.coinsPrice || !state.name}
          isPrincipal
          value={ON_BOARDING.ADD}
          className="on-boarding__buttons-add"
          style={{ backgroundColor: "#FFF" }}
          onClick={() => {
            props.onAdd(state);
            setAdded(true);
            setState({
              image: "",
              file: "",
              name: "",
              coinsPrice: "",
            });
          }}
        />
      </div>
      <div
        className="on-boarding__card"
        style={{ marginLeft: -30, marginTop: 50 }}
      >
        <div className="on-boarding__buttons" style={{ marginTop: 0 }}>
          <GoBack onClick={props.onBack} />
          <Button
            isPrincipal
            value={ON_BOARDING.CONTINUE}
            style={{ backgroundColor: "#FFF" }}
            onClick={() => props.onSubmit(state)}
            disabled={!added && props.rewards?.length === 0}
          />
        </div>
      </div>
    </form>
  );
}
