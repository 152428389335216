const dictionary = {
  LANG: "en",
  AUTH: {
    LOG_IN: {
      TITLE: "Log in",
      FORM: {
        EMAIL: "Email",
        PASSWORD: "Password",
      },
      REMEMBER: "Remember password",
      FORGOT: "I forgot my password",
      BUTTON: "Log in",
    },
  },
  LAYOUT: {
    TABS: {
      LOG_OUT: "Log out  ",
    },
  },
  NAV: {
    PROFILE: "Profile",
    SIGN_OUT: "Log out",
  },
  APP: {
    ON_BOARDING: {
      STEPS: "Step",
      OF: "of",
      CONTINUE: "Continue",
      PUBLISH: "Publish",
      ADD: "Add",
      OPEN: "Open",
      _1: {
        CURRENT: "Current password",
        PASSWORD: "New password",
        REPEAT: "Repeat password",
      },
      _2: {
        NAME: "Company name",
        CATEGORY: "Category",
        DESCRIPTION: "Description",
      },
      _3: {
        EMPTY: "Once you have created a reward, it will be displayed here.",
      },
      _4: {
        EMPTY: "Once you have created a link, it will be displayed here.",
        TITLE: "Preview and publish",
        ACCOUNT: {
          TITLE: "Account details",
          LOGO: "Logo",
          NAME: "Name",
          DESCRIPTION: "Description",
          CATEGORY: "Category",
        },
        REWARDS: {
          TITLE: "Rewards",
        },
        LINKS: {
          TITLE: "Links",
        },
      },
    },
    ADMIN: {
      USERS: {
        OVERVIEW: {
          OPEN: "Overview",
          CLOSE: "Close Overview",
        },
        EXPORT: "Download Excel",
        DATAGRID: {
          HEADER: {
            INSTAGRAMUSERNAME: "Instagram User Name",
            NAME: "Name",
            GOLD: "Plan",
            PHONE: "Phone Number",
            EMAIL: "Email",
            COUNTRY: "Country",
            PLATFORM: "iOS / Android",
            FAVFILTERS: "Favourite Filters",
          },
        },
      },
      CATEGORIES: {
        CARD: "Categories",
        TITLE: "Create a new Category!",
        PARAGRAPH: "Edit the category you want to offer",
        FORM: {
          NAME: "Name of the category",
          ACTIVE: "Active",
          DISABLED: "Disabled",
          CREATE: "Submit Category",
        },
      },
    },
    HOME: {
      REWARDS: {
        CARD: "Rewards",
        TITLE: "Create new rewards!",
        PARAGRAPH:
          "Edit the rewards you want to offer and the amount of coins they cost",
        FORM: {
          NAME: "Name of reward",
          DESCRIPTION: "Description",
          COINS: "Coins",
          CREATE: "Create reward",
        },
      },
      LINKS: {
        TITLE: "Links",
        TYPE: "Type",
        LINK: "Link",
        FORM: {
          TITLE: "Create new link",
          TYPE: "Reward type",
          URL: "url",
          CREATE: "Create link",
        },
      },
    },
    STATISTICS: {
      TITLE: "Check your stats!",
      DESCRIPTION:
        "See how your rewards are performing and how many people are claiming them!",
      CARDS: {
        CLAIMED: {
          TITLE: "Rewards claimed today",
        },
        WEEKLY: {
          TITLE: "Weekly chart",
          LAST_WEEK: "than last week",
        },
        DAYS: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
      },
    },
    PROFILE: {
      UPDATE: "Update",
    },
    MODALS: {
      EDIT: {
        REWARDS: {
          TITLE: "Edit reward",
        },
        CATEGORY: {
          TITLE: "Edit category",
        },
        LINKS: {
          TITLE: "Edit link",
        },
        UPDATE: "Update",
      },
      DELETE: {
        REWARDS: {
          TITLE: "Delete this reward?",
        },
        CATEGORY: {
          TITLE: "Delete this category?",
        },
        LINKS: {
          TITLE: "Delete this link?",
        },
        SURE: "You will not be able to recover it",
        DELETE: "Delete",
      },
    },
    USER_STATISTICS: {
      TITLE: "User statistics",
      DESCRIPTION:
        "Here you can see the statistics and analysis of the users and all their actions",
      PARAGRAPH: "COMING SOON",
      GRAPHICS: {
        USER_TIME: {
          TITLE: "Users throught time",
        },
        USERS_AGE: {
          TITLE: "Users age",
          USERS: "Users",
        },
        USERS_GENERE: {
          TITLE: "Users genere",
          MALE: "Male",
          FEMALE: "Female",
        },
        USERS_PLATFORM: {
          TITLE: "Platform",
          ANDROID: "Android",
          IOS: "IOS",
        },
        USERS_LOCATION: {
          TITLE: "Location",
        },
        MODE_SUMMARY: {
          TITLE: "Mode summary per user",
          REWARDS: "Rewards",
          PRIVACY: "Privacy",
        },
        USERS_DISTRIBUTION: {
          TITLE: "Distribution of users by post code",
        },
      },
    },
  },
};

export default dictionary;
