import styled from "styled-components";
import { GrayScale } from "../../../assets/theme/Colors";
import { device } from "../../../assets/theme/ResponsiveSizes"


export const Styles = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80%;
  width: auto;
  height:auto;

  .DBHeader {
    padding: 0 1.8rem;
    &__Search-Input {
      margin-left: 2rem;
      max-width: 12rem;
      height: 40px;
      background-color: ${GrayScale.lightOverDark};
    }
  }


  .SalesForm {
    background-color: ${GrayScale.LightGray};
    border-radius: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 10rem;
    min-width:60%;

    @media ${device.mobileS} { 
      width: 100%;
    }

    @media ${device.laptop} { 
      width: 80%;
    }
    @media ${device.laptopL} { 
      width: 60%;
    }

    @media ${device.desktopL} { 
      width: 60%;
    }

    &__Content {
      /* flex: 1; */
      display: flex;
      flex-direction: column;
      width: 50%;

      @media ${device.mobileS} { 
        width:95%;
      }  

      @media ${device.tablet} { 
        width:80%;
      }  

      @media ${device.laptop} { 
        width:70%;
      }  

    }


    &__Title {
      text-align: center;
      margin-bottom:20%;

      @media ${device.laptop} { 
        font-size: 1.8rem;
        font-weight:600;
      }

      @media ${device.laptopL} { 
        font-size: 2.4rem;
        font-weight:600;
      }
    }

    &__Input {
      margin: 0.2rem 0;
      background-color: ${GrayScale.lightOverDark};
    }

    &__Select {
      margin-bottom: 0.5rem;
    }

    &__InputTwo {
      height: 12rem;
      margin: 0.2rem 0;
      background-color: ${GrayScale.lightOverDark};
    }

    &__Actions {
      &-button {
        min-width: 16rem;
      }
    }

    &__SelectInput {
      margin: 0.5rem 0;

      .SelectInput {
        border-radius: 3rem;
        background-color: ${GrayScale.lightOverDark};

        & .MuiSelect-select {
          padding: 0.5rem 1.5rem;
        }
      }

      .SelectInput > fieldset {
        border: 0;
      }
    }
  }
`;
