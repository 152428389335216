import { Select } from "antd";
import React, { ReactNode } from "react";
import "../styles/base/input.scss";
import { Icon } from "semantic-ui-react";

export interface IOptions {
  _id: string;
  name: string;
  image: string;
}

export default function Input(props: {
  placeholder: string;
  onChange: any;
  name: string;
  disabled?: boolean;
  value?: string | number | readonly string[] | undefined;
  className?: string;
  style?: React.CSSProperties;
  isFull?: boolean;
  maxLength?: number;
  isSelector?: boolean;
  options?: IOptions[];
  isArea?: boolean;
  type?: React.HTMLInputTypeAttribute | undefined;
  infoText?: string;
  showInfo?: boolean;
  handleShowInfo?: () => void;
}) {
  if (props.isArea) {
    return (
      <textarea
        disabled={props?.disabled}
        maxLength={100}
        placeholder={props.placeholder}
        style={props?.style}
        value={props?.value}
        onChange={(v) => props.onChange(v.target.value, props.name)}
        className={`input-container--full input-area ${
          props.className ? props.className : ""
        }`}
      />
    );
  } else if (props.isSelector) {
    return (
      <select
        disabled={props?.disabled}
        placeholder={props.placeholder}
        style={props?.style}
        defaultValue={props?.value}
        value={props?.value}
        onChange={(v) => props.onChange(v.target.value, props.name)}
        className={`input-container--full ${
          props.className ? props.className : ""
        }`}
      >
        {props.options ? (
          props.options?.map((option: IOptions) => (
            <option key={`${option._id}`} value={option._id}>
              {option.name}
            </option>
          ))
        ) : (
          <>
            <option value="Best seller">Best seller</option>
            <option value="Specials">Specials</option>
            <option value="Music">Music</option>
            <option value="Menu">Menu</option>
          </>
        )}
      </select>
    );
  }

  if(props.infoText){
    return (
      <>
        <div className="input-with-info-container">
          <input
            className={`input-container${props.isFull ? "--full-with-info" : ""} ${
              props.className ? props.className : ""
            }`}
            maxLength={props.maxLength}
            disabled={props?.disabled}
            type={props.type}
            style={props.style}
            onChange={(v) => props.onChange(v.target.value, props.name)}
            placeholder={props.placeholder}
            value={props.value}
          />
          <div onClick={props.handleShowInfo}>
            <Icon className="icon-info" name={"info"}></Icon>
          </div>
        </div>
        <div className="input-container-info-text">
          {
            props.showInfo && <p>{props.infoText}</p>
          }
        </div>
      </>
    );
  }
  return (
    <input
      className={`input-container${props.isFull ? "--full" : ""} ${
        props.className ? props.className : ""
      }`}
      maxLength={props.maxLength}
      disabled={props?.disabled}
      type={props.type}
      style={props.style}
      onChange={(v) => props.onChange(v.target.value, props.name)}
      placeholder={props.placeholder}
      value={props.value}
    />
  );
}
