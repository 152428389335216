import { useState } from "react";

import "../styles/pages/AdminUploadCampaignScreen.scss";
import Button from "../base/Button.base";
import CampaignForm from "../components/campaigns/CampaignForm";
import { useForm } from "../hooks/useForm";
import ChallengesList from "../components/campaigns/ChallengesList";
import { uploadCampaign } from "../api/campaigns.api";
import { set } from "store";
// import { getAllCampaignCategories, uploadCampaign } from "../api/campaign.api";

export interface ICampaignForm {
  campaignName: string;
  campaignDescription: string;
  campaignBudget: string;
  startDate: string;
  endDate: string;
}

export interface IChallenge {
  challenge_reward_amount: string;
  challenge_data_url: string;
  challenge_data_platform: "instagram" | "twitter" | "tiktok" | "website";
  challenge_data_title: string;
  challenge_data_instructions: string;
}

export default function AdminUploadCampaignScreen() {
  const [sendingForm, setSendingForm] = useState(false);

  const { form, onChange, setFormValue } = useForm<ICampaignForm>({
    campaignName: "",
    campaignDescription: "",
    campaignBudget: "",
    startDate: "",
    endDate: "",
  });

  const [challenges, setChallenges] = useState<IChallenge[]>([]);

  const [showSection, setShowSection] = useState("campaigns");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [imageCampaignFile, setImageCampaignFile] = useState<File[] | null>(
    null
  );
  const [imageCampaignUrl, setImageCampaignUrl] = useState<string>("");

  const [imageBannerFile, setImageBannerFile] = useState<File[] | null>(null);
  const [imageBannerUrl, setImageBannerUrl] = useState<string>("");

  const uploadCampaignHandler = async () => {
    if (sendingForm) {
      return;
    }

    if (!imageCampaignFile) {
      return setErrorMessage("Image file empty.");
    }
    if (imageCampaignFile![0].size > 1500000) {
      return setErrorMessage("Max image size 1.5MB");
    }
    if (imageCampaignFile![0].type === "image/jpeg" || "image/png") {
      console.log("success");
    }

    if (!imageBannerFile) {
      return setErrorMessage("Banner file empty.");
    }
    if (imageBannerFile![0].size > 1500000) {
      return setErrorMessage("Max banner size 1.5MB");
    }
    if (imageBannerFile![0].type === "image/jpeg" || "image/png") {
      console.log("success");
    }

    if (form.campaignName === "") {
      return setErrorMessage("Campaign name is required.");
    }

    if (form.campaignBudget === "") {
      return setErrorMessage("Campaign budget is required.");
    }

    if (form.startDate === "") {
      return setErrorMessage("Start date is required.");
    }

    if (challenges.length === 0) {
      return setErrorMessage("At least one challenge is required.");
    }

    setSendingForm(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const response = await uploadCampaign({
        ...form,
        challenges,
        imageCampaignFile: imageCampaignFile,
        imageBannerFile: imageBannerFile,
      });

      if (response) {
        setChallenges([]);
        setFormValue({
          campaignName: "",
          campaignDescription: "",
          campaignBudget: "",
          startDate: "",
          endDate: "",
        });
        setImageCampaignUrl("");
        setImageBannerUrl("");
        setImageCampaignFile(null);
        setImageBannerFile(null);
        setSuccessMessage("Campaign uploaded successfully");
      }
    } catch (error) {
    } finally {
      setSendingForm(false);
    }
  };

  return (
    <div className="AdminUploadCampaignScreen">
      <div className="AdminUploadCampaignScreen__Content">
        <div className="AdminUploadCampaignScreen__Content-sections">
          <Button
            className={`AdminUploadCampaignScreen__Content-form-button section-button ${
              showSection === "campaigns" ? "section-button-active" : ""
            }`}
            loading={sendingForm}
            onClick={() => {
              setShowSection("campaigns");
            }}
            value="Campaigns"
          />
          <Button
            className={`AdminUploadCampaignScreen__Content-form-button section-button ${
              showSection === "challenges" ? "section-button-active" : ""
            }`}
            loading={sendingForm}
            onClick={() => {
              setShowSection("challenges");
            }}
            value="Challenges"
          />
        </div>
        {showSection === "campaigns" && (
          <CampaignForm
            sendingForm={sendingForm}
            form={form}
            onChange={onChange}
            imageUrl={imageCampaignUrl}
            imageBannerUrl={imageBannerUrl}
            uploadCampaignHandler={uploadCampaignHandler}
            setImageCampaignFile={setImageCampaignFile}
            setImageCampaignUrl={setImageCampaignUrl}
            setImageBannerFile={setImageBannerFile}
            setImageBannerUrl={setImageBannerUrl}
            isUploadEnabled={challenges.length > 0}
          />
        )}
        {showSection === "challenges" && (
          <ChallengesList
            challenges={challenges}
            setChallenges={setChallenges}
          />
        )}
        <div className="AdminUploadCampaignScreen__Content-result">
          {successMessage ? (
            <span className="AdminUploadCampaignScreen__Content-result--success">
              {successMessage}
            </span>
          ) : null}
          {errorMessage ? (
            <span className="AdminUploadCampaignScreen__Content-result--error">
              {errorMessage}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}
