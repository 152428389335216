import {createGlobalStyle} from 'styled-components';
import {darken} from 'polished';
import { MainColors, Background, GrayScale } from '../../../assets/theme/Colors';


export const SelectGlobalStyles = createGlobalStyle`
.MuiSelect-select.MuiSelect-select{
  padding: 0;
  padding-left: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.Select{
  & .MuiList-root.MuiList-root{
    padding: 0;
    
    & .MuiMenuItem-root.MuiMenuItem-root{
      background-color: ${Background.bgLight};
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-size: 1.2rem; /* Base 16px; */
      line-height: 1.5rem;
      font-family: Poppins, system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';

      &:hover{
        background-color: ${darken(0.05, Background.bgLight)};
      }
    
      &.Mui-selected.Mui-selected{
        background-color: ${MainColors.primary};
        color: ${GrayScale.lightOverDark};

        &:hover{
          background-color: ${darken(0.05, MainColors.primary)};
        }
      }
    }
  }
}
`;
