import { FC, useEffect, useState } from "react";
import SalesScreenView from "./Views/SalesScreenView";

import { AlertColor } from "@mui/material/Alert";

import { ScreenLoader } from "../../components/UI/Spinners/presets";
import { useSearchParams} from "react-router-dom";
import axios from "axios";
import filtrooApi from "../../api/filtrooApi";

const SalesScreen: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const [showAlert, setShowAlert] = useState(false);
  const [statusAlert, setStatusAlert] = useState<AlertColor>("success");
  const [
    msg,
    setMsg
  ] = useState<string>("");


  const givePoints = async (operationData:any) => {
    try{
      const res = await filtrooApi.post("/generate-points", operationData)
      return true
    }catch(error){
      console.log(error)
      return false
    }

  }

  const onSubmit = async (operationData:any) => {
    const created = await givePoints(operationData);
    if (created) {
      setShowAlert(true);
      setStatusAlert("success")
      setMsg("Points given successfully")
      return created;
      
    } else {
      setStatusAlert("warning")
      setMsg("Error generating points")
      setShowAlert(true);
      return undefined;
    }
  };


  return (
    <SalesScreenView
      onSubmit={onSubmit}
      showAlert={showAlert}
      statusAlert={statusAlert}
      msg={msg}
      setShowAlert={setShowAlert}
      setStatusAlert={setStatusAlert}
    />
  );
};

export default SalesScreen;
