import { Table } from "antd";
import { useTranslation } from "../../../contexts/LocalizeContext";
import Overview, { IOverviewUsers } from "./Overview";
import LogoGold from "../../../assets/Isotipo_Dorado.png";
import LogoFree from "../../../assets/Isotipo_Azul.png";
import { Eye, EyeOff } from "react-feather";
import { MouseEventHandler, useState } from "react";

// AVISAR A DIEGO QUE LOS USUARIOS ANTIGUOS PUEDEN ROMPER LA TABLA POR QUE NO COINCIDEN SUS OBJECT KEYS CON LOS ACTUALES

const columns = (ADMIN: any) => {
  const headers: string[] = [
    "instagramUserName",
    "name",
    "gold",
    "email",
    "phone",
    "country",
    "platform",
    "favFilters",
  ];

  const columns = headers.map((header: String) => {
    if (header === "favFilters") {
      return [
        {
          key: header,
          dataIndex: header,
          title: (
            <div className="datagrid-users-header">
              {(ADMIN.USERS.DATAGRID.HEADER as any)[header.toUpperCase()]}
            </div>
          ),
          render: (text: string) => {
            return text.length === 0 ? (
              <div className="datagrid-users-cell">Empty</div>
            ) : (
              <div className="datagrid-users-cell">Expand</div>
            );
          },
        } as any,
        Table.EXPAND_COLUMN,
      ];
    }

    return {
      key: header,
      dataIndex: header,
      title: (
        <div className="datagrid-users-header">
          {(ADMIN.USERS.DATAGRID.HEADER as any)[header.toUpperCase()]}
        </div>
      ),
      render: (text: string) => {
        return (
          <div className="datagrid-users-cell">
            {header !== "gold" ? (
              text
            ) : (
              <img
                style={{ width: "35px" }}
                alt={typeof text === "string" ? text : ""}
                src={text ? LogoGold : LogoFree}
              />
            )}
          </div>
        );
      },
    } as any;
  });

  return columns.flat();
};

const customExpandIcon = (props: any) =>
  props.expandable ? (
    props.expanded ? (
      <EyeOff
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
        className="datagrid-users-expand-icon"
      />
    ) : (
      <Eye
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
        className="datagrid-users-expand-icon"
      />
    )
  ) : (
    <Eye className="datagrid-users-expand-icon-disabled" />
  );

export interface IData {
  _id: string;
  key: string;
  name: string;
  instagramUserName: string;
  gold: boolean;
  email: string;
  phone: string;
  country: string;
  platform: string;
  favFilters: favFilter[];
}

interface favFilter {
  image: string;
  video: string;
  name: string;
}

interface IDatagridUsers extends IOverviewUsers {
  showOverview: boolean;
  users: IData[];
  handlePagination(type: string): void;
  usersTotal: number;
}

const DatagridUsers = ({
  showOverview,
  users,
  handlePagination,
  usersOsStatistics,
  usersCountryStatistics,
  usersTotal,
}: IDatagridUsers) => {
  const {
    APP: { ADMIN },
  } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className="datagrid-users">
      <Table
        className="overlay-table"
        columns={columns(ADMIN)}
        loading={false}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 5,
          itemRender: (page, type, Element) => {
            if (type === "page")
              return (
                <button className="datagrid-users-pages" disabled>
                  {currentPage}
                </button>
              );
            return (
              <div
                className="ant-pagination-item-link datagrid-users-pages-buttons"
                onClick={() => {
                  if (type === "prev" && currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                  } else if (
                    type === "next" &&
                    currentPage < Math.ceil(usersTotal / 5)
                  ) {
                    setCurrentPage(currentPage + 1);
                  }
                  handlePagination(type);
                }}
              >
                {Element}
              </div>
            );
          },
        }}
        dataSource={true ? users : []}
        expandable={{
          rowExpandable: (record) => record.favFilters.length > 0,
          expandIcon: (props) => customExpandIcon(props),
          expandedRowRender: (record) => (
            <div className="datagrid-users-favExpanded">
              {record.favFilters.map((filter, index) => (
                <div
                  key={record.key + index}
                  className="datagrid-users-favExpanded-item"
                >
                  <p className="datagrid-users-favExpanded-item-name">
                    {filter.name}
                  </p>
                  {filter.video ? (
                    <video
                      muted
                      onMouseEnter={(e: any) => e.target.play()}
                      onMouseLeave={(e: any) => {
                        e.target.pause();
                        e.target.load();
                      }}
                      poster={filter.image ? filter.image : undefined}
                    >
                      <source
                        src={filter.video}
                        type={`video/${filter.video.substring(
                          filter.video.lastIndexOf(".") + 1,
                          filter.video.length
                        )}`}
                      />
                    </video>
                  ) : (
                    filter.image && <img src={filter.image} alt="Filter" />
                  )}
                </div>
              ))}
            </div>
          ),
        }}
      />
      {showOverview && (
        <Overview
          usersCountryStatistics={usersCountryStatistics}
          usersOsStatistics={usersOsStatistics}
        />
      )}
    </div>
  );
};

export default DatagridUsers;
