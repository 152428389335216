import "../styles/pages/AdminFilterDetailScreen.scss";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../base/Button.base";
import { useGetFilterDetail } from "../hooks/useGetFilterDetail";
import { approvedFilter } from "../api/filter.api";
import Modal from "../components/Modal";
import { Loader } from "semantic-ui-react";
import axios from "axios";
interface DataToEmail {
  email: string;
  name: string;
  filterName: string;
  status?: string;
  rejectReason?: string[];
}
export default function AdminFilterDetailScreen() {
  const { id: filterId } = useParams();
  const modalRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [reject, setReject] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState([]);

  const { filter, isLoading } = useGetFilterDetail(filterId!);

  const navigate = useNavigate();

  const tokenWithQuotes = localStorage.getItem("TOKEN");

  const token =
    tokenWithQuotes!.startsWith('"') && tokenWithQuotes!.endsWith('"')
      ? tokenWithQuotes!.slice(1, -1)
      : tokenWithQuotes;

  const toggleRejectionReason = (reason: string) => {
    //@ts-ignore
    if (rejectionReasons.includes(reason)) {
      // Si ya está seleccionado, quita la razón de la lista.
      setRejectionReasons(rejectionReasons.filter((r) => r !== reason));
    } else {
      // Si no está seleccionado, agrégalo a la lista.
      //@ts-ignore

      setRejectionReasons([...rejectionReasons, reason]);
    }
  };

  const rejectFilterHandler = async (status: string) => {
    try {
      if (modalRef.current) {
        modalRef.current.open();
      }

      setReject(true);
      setModalMessage("Filter rejected successfully");
      setLoading(true);

      // await approvedFilter(filterId!, "rejected", rejectionReasons);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const approvedFilterHandler = async (status: string) => {
    try {
      setLoading(true);
      await approvedFilter(filterId!, status);
      setLoading(false);
      setReject(false);
      setModalMessage("Filter approved successfully");

      if (modalRef.current) {
        modalRef.current.open();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleModalClose = async (rejectionReasons: string[]) => {
    console.log(rejectionReasons);
    if (rejectionReasons.length > 0) {
      await approvedFilter(filterId!, "rejected", rejectionReasons);
    }
    if (modalRef.current) {
      modalRef.current.close();
    }

    navigate("/admin/approved-filter");
  };

  if (isLoading) {
    return <Loader active={true}>Loading...</Loader>;
  }

  return filter ? (
    <div className="General_Container">
      <div className="Image_Container">
        <img className="Image_Filter" src={filter?.image} alt="filter" />
      </div>
      <div className="Info_Container">
        <div className="Detail_Info">
          <h3>Filter Name</h3>
          <p className="Detail_Info_Text"> {filter && filter.name}</p>
        </div>
        <div className="Detail_Info">
          <h3>Category Name</h3>
          <p className="Detail_Info_Text">
            {" "}
            {filter &&
              (filter.category?.name[0].toUpperCase() +
                filter.category?.name.slice(1) ||
                "")}
          </p>
        </div>
        <div className="Detail_Info">
          <h3>Link of the filter</h3>
          <a
            className="Link"
            href={filter && filter.instaURL}
            target="_blank"
            rel="noreferrer"
          >
            {filter.instaURL}
          </a>
        </div>

        <div className="Detail_Info">
          <h3>Link of the video</h3>
          <p>
            <a
              className="Link"
              href={filter && filter.video}
              target="_blank"
              rel="noreferrer"
            >
              {filter.video}
            </a>
          </p>
        </div>
        <div className="Detail_Info">
          <h3>TikTok account to be mentioned</h3>
          <p className="Detail_Info_Text"> {filter && filter.locationName}</p>
        </div>

        <div className="Buttons">
          <Button
            style={{ backgroundColor: "#DE393B", color: "white" }}
            className="AdminUploadFilterScreen__Content-form-button"
            loading={loading}
            onClick={() => rejectFilterHandler("rejected")}
            value="Reject"
          />
          <Button
            isPrincipal
            className="AdminUploadFilterScreen__Content-form-button"
            loading={loading}
            onClick={() => approvedFilterHandler("approved")}
            value="Approve"
          />
        </div>
      </div>
      <Modal title="Success" ref={modalRef}>
        <div className="Modal">
          <p>{modalMessage}</p>
          {reject && (
            <div>
              <h3>Reasons for Rejection:</h3>
              <ul>
                <li style={{ listStyleType: "none" }}>
                  <label>
                    <input
                      style={{ marginRight: 15 }}
                      type="checkbox"
                      //@ts-ignore
                      checked={rejectionReasons.includes("Filter Name")}
                      onChange={() => toggleRejectionReason("Filter Name")}
                    />
                    Filter Name
                  </label>
                </li>
                <li style={{ listStyleType: "none" }}>
                  <label>
                    <input
                      style={{ marginRight: 15 }}
                      type="checkbox"
                      //@ts-ignore
                      checked={rejectionReasons.includes("Filter Image")}
                      onChange={() => toggleRejectionReason("Filter Image")}
                    />
                    Filter Image
                  </label>
                </li>

                <li style={{ listStyleType: "none" }}>
                  <label>
                    <input
                      style={{ marginRight: 15 }}
                      type="checkbox"
                      checked={rejectionReasons.includes(
                        //@ts-ignore
                        "Video"
                      )}
                      onChange={() => toggleRejectionReason("Video")}
                    />
                    Filter Video
                  </label>
                </li>
              </ul>
            </div>
          )}
          <Button
            isPrincipal
            style={{ marginTop: 35, marginBottom: 15 }}
            value="Ok"
            onClick={() => handleModalClose(rejectionReasons)}
          />
        </div>
      </Modal>
    </div>
  ) : (
    <div></div>
  );
}
