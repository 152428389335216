import { ICampaignForm } from "../../screens/AdminUploadCampaignScreen";
import Input from "../../base/Input.base";
import Button from "../../base/Button.base";
import Dragger from "../../base/Dragger.base";

const CampaignForm = ({
  sendingForm,
  form,
  imageUrl,
  imageBannerUrl,
  onChange,
  setImageCampaignFile,
  setImageCampaignUrl,
  setImageBannerFile,
  setImageBannerUrl,
  uploadCampaignHandler,
  isUploadEnabled,
}: {
  sendingForm: boolean;
  form: ICampaignForm;
  imageUrl: string;
  imageBannerUrl: string;
  onChange: (value: string, field: keyof ICampaignForm) => void;
  setImageCampaignFile: (file: File[] | null) => void;
  setImageCampaignUrl: (url: string) => void;
  setImageBannerFile: (file: File[] | null) => void;
  setImageBannerUrl: (url: string) => void;
  uploadCampaignHandler: () => void;
  isUploadEnabled?: boolean;
}) => {
  return (
    <>
      <h1 className="AdminUploadCampaignScreen__Content-title">
        Upload a new campaign
      </h1>
      <div className="AdminUploadCampaignScreen__Content-form">
        <Input
          isFull
          className="AdminUploadCampaignScreen__Content-form-input"
          name="campaignName"
          type="text"
          value={form.campaignName}
          placeholder="Campaign name *"
          onChange={(value: any) => onChange(value, "campaignName")}
        />
        <Input
          isFull
          className="AdminUploadCampaignScreen__Content-form-input"
          name="campaignDescription"
          type="text"
          isArea
          value={form.campaignDescription}
          placeholder="Campaign description"
          onChange={(value: any) => onChange(value, "campaignDescription")}
        />
        <Input
          isFull
          className="AdminUploadCampaignScreen__Content-form-input"
          name="campaignBudget"
          type="number"
          value={form.campaignBudget}
          placeholder="Campaign budget amount *"
          onChange={(value: any) => onChange(value, "campaignBudget")}
        />
        <Input
          isFull
          className="AdminUploadCampaignScreen__Content-form-input"
          name="startDate"
          type="text"
          value={form.startDate}
          placeholder="Start Date (DD/MM/YYYY) *"
          onChange={(value: any) => onChange(value, "startDate")}
        />
        <Input
          isFull
          className="AdminUploadCampaignScreen__Content-form-input"
          name="endDate"
          type="text"
          value={form.endDate}
          placeholder="End Date (DD/MM/YYYY) *"
          onChange={(value: any) => onChange(value, "endDate")}
        />

        <div className="AdminUploadCampaignScreen__Content-campaigns-draggers">
          <div className="AdminUploadCampaignScreen__Content-campaigns-draggers-item">
            <h3>Campaign Image</h3>
            <Dragger
              defaultImage={imageUrl}
              style={{ marginTop: 15, marginBottom: 20 }}
              onChange={(file: any, url: string) => {
                setImageCampaignFile(file);
                setImageCampaignUrl(url);
              }}
            />
          </div>
          <div className="AdminUploadCampaignScreen__Content-campaigns-draggers-item">
            <h3>Campaign Banner</h3>
            <Dragger
              defaultImage={imageBannerUrl}
              style={{ marginTop: 15, marginBottom: 20 }}
              onChange={(file: any, url: string) => {
                setImageBannerFile(file);
                setImageBannerUrl(url);
              }}
            />
          </div>
        </div>

        <Button
          isPrincipal
          className="AdminUploadCampaignScreen__Content-form-button"
          loading={sendingForm}
          onClick={uploadCampaignHandler}
          value="Upload campaign"
          disabled={!isUploadEnabled}
        />
      </div>
    </>
  );
};

export default CampaignForm;
