import axios, { AxiosInstance } from "axios";
// import { Result } from "../helpers/Result";

// import { ENV } from "../env";
// import { StorageService } from "./StorageService";
// import { useAuthStore } from "@/stores";

// import LoggerService from '@/services/LoggerService';

const ENV = {
  // SERVER_URL: "http://localhost:8080",
  SERVER_URL: "https://api.v2.filtroo.co",
};

export class ApiService {
  client: AxiosInstance;
  // storage: StorageService;

  constructor(server_url?: string) {
    this.client = axios.create({
      baseURL: server_url || ENV.SERVER_URL,
    });
    // this.storage = new StorageService();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.client.interceptors.request.use(async (config: any) => {
      // LoggerService.logRequest(config);
      // const auth = await this.storage.getAsyncStorage("auth-storage");

      return {
        ...config,
        headers: {
          ...config.headers,
          // authorization: auth.state?.token ? auth.state?.token : undefined,
          Accept: "application/json",
        },
      };
    });

    // this.client.interceptors.response.use(
    //   (response) => response,
    //   async (error) => {
    //     const originalRequest = error.config;

    //     // Si es un error 401 o 403 (sin autorización o token vencido)
    //     if (error.response?.status === 401 && !originalRequest._retry) {
    //       originalRequest._retry = true;

    //       // Intentar renovar el token
    //       const result = await refreshToken();

    //       if (result.isSuccess) {
    //         // Si el token se renovó, actualiza el header de autorización y repite la petición
    //         originalRequest.headers.Authorization = result.success.access_token;
    //         return this.client(originalRequest);
    //       } else {
    //         // Si no se pudo renovar el token, realizar el logout
    //         useAuthStore.getState().logout();
    //       }
    //     }

    //     return Promise.reject(error);
    //   }
    // );
  }
}

// export async function refreshToken(): Promise<
//   Result<{ access_token: string }, Error>
// > {
//   const { refresh } = useAuthStore.getState(); // Obtener el refresh token de Zustand

//   if (!refresh) {
//     return Result.fail(new Error("No refresh token found"));
//   }

//   // Llamada a la API para renovar el token
//   const result = await refreshAuthToken({ refresh_token: refresh });

//   if (result.isSuccess) {
//     // Si se renovó correctamente, actualiza el estado del store de Zustand
//     useAuthStore.setState({
//       token: result.success.access_token,
//       status: "authorized",
//     });
//   }

//   return result;
// }

// async function refreshAuthToken({
//   refresh_token,
// }: {
//   refresh_token: string;
// }): Promise<Result<{ access_token: string }, Error>> {
//   try {
//     const { client: Api } = new ApiService(ENV.AUTH_URL);
//     const response = await Api.post("/auth/refresh", null, {
//       headers: {
//         refresh_token,
//       },
//     });
//     return Result.ok(response.data);
//   } catch (error) {
//     return Result.fail(new Error("Failed to refresh token"));
//   }
// }
