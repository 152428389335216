import { useEffect, useState } from 'react';
import { createCode } from '../api/pointsGenerator.api';

export const usePointsGenerator = <T extends Object>( initState: T ) => {
    
    const [state, setState] = useState( initState );
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage ] = useState("")

    const generateCode = async (code: string, type: "unique" | "multi" = 'unique', quantity = 0) => {
        setIsLoading(true)
        try{
            const response = await createCode(code, type, quantity)

            setIsLoading(false)
            return response
        } catch(error:any) {
            setErrorMessage(error.message || error.msg)
            setIsLoading(false)
            return false
        }
    }

    return {
        ...state,
        generateCode,
        loading: isLoading,
        errorMessage
    };
};