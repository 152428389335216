import {transparentize} from 'polished';
import styled from 'styled-components';
import {GrayScale, MainColors, Status} from '../../../../assets/theme/Colors';
import {SpinnerPresets} from '../../Spinners';
import {Button, ButtonProps} from '../Button';

const Styles = styled(Button)`
  background-color: ${({disabled}) => (disabled ? Status.disabled : "#4592d3")};
  border: ${({disabled, loading}) => (disabled && !loading ? `2px solid ${Status.disabled}` : `2px solid #4592d3`)};
  color: ${GrayScale.lightOverDark};
  padding: 1rem 1.5rem;
  border-radius: 3rem;
  transition: all 400ms ease-in-out;

  &:hover {
    background-color: ${transparentize(0.1, "white")};
    border: 2px solid ${transparentize(0.1, "white")};
    color: ${transparentize(0.1, "black")}
  }
`;

export function ButtonRoundedFilledPrimary({...props}: ButtonProps) {
  return <Styles {...props} loader={<SpinnerPresets.Circular className='Button__loader' color={GrayScale.lightOverDark} />} />;
}
